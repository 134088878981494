import React ,{useState}  from 'react'
import { withPrefix ,navigate} from 'gatsby'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
import { InView } from 'react-intersection-observer'
// import ReactMarkdown from "react-markdown"
import ThreeDepth from '../ThreeD/DepthImage'
import { connect } from 'react-redux';
import { clicktoSection  } from '../actions';
import { BgImage } from 'gbimage-bridge';
import { getImage } from "gatsby-plugin-image"



//
const DepthImage = ({clicktoSection, children, data, pinspace, headerRef, controller,scrollProgress,sectionnumber,section,scrubspeed,anticipate,setup,scroller, setScrollPos, scrollref, pagenav, location }) => {
    const [inview, viewtoggle] = useState(false);
    let revealRef = React.createRef();
    const mediaQuery = typeof window !== "undefined" && window.innerWidth;

    console.log(mediaQuery)

  function scrolltoclick(e, section){

    e.stopPropagation()
      clicktoSection(section)
        //navigate("/")
  }



if(data.type === "ThreeD_depth_kicker"){

  return(
  <InView as="div"  className="relative DContainer" threshold={0.3} onChange={(inView, entry) => viewtoggle(inView)}>



    <div className="DepthContainer">
    {mediaQuery <= 640 ?
        <BgImage image={getImage(data.main_3d.localFile)} Tag="div" className="h-full w-full z-10"/>
    :   <ThreeDepth dataDepth={data.depth_3d} dataImage={data.main_3d} inview={inview}/>
    }

    </div>
</InView>

);


}else{


  return(

<div ref={revealRef} className="relative DContainer">
    <div className="absolute bg-gradient-to-l md:from-Grennery-bg50  from-Grennery-bg80 w-full h-full">
      <div className="absolute text-right transform -translate-y-1/3 top-1/3 md:-translate-y-1/3 md:top-1/3 md:right-12 right-8">

        <h1 className=" text-white lg:text-14xl md:text-10xl text-6xl hsm:text-8xl leading-vtight " >{data.title}</h1>
        <div className="flex">
        <div className=" md:w-2/6 w-1/6"></div>
        <div className="text-right text-white lg:text-3xl md:text-3xl text-2-5xl hsm:text-2-5xl italic leading-tight md:w-4/6 w-full md:mt-0 hsm:mt-2 mt-5">{data.subtitle}</div>

        </div>
        <div className="flex mt-10">
        <div className="w-8/12"></div>
        <div className="cursor-pointer bg-TrueBlue bg-opacity-60 border-2 border-white md:w-4/12 w-1/2 text-center text-white p-2 pt-2.5 font-body text-lg transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer" onClick={(e) =>  scrolltoclick(e,"about")}>Find out more</div>
      </div>
            </div>
      </div>
  <InView as="div" className="DepthContainer" threshold={0.3} onChange={(inView, entry) => viewtoggle(inView)}>

    {mediaQuery <= 640 ?
        <BgImage image={getImage(data.main_3d.localFile)} Tag="div" className="h-full w-full z-10"/>
    :   <ThreeDepth dataDepth={data.depth_3d} dataImage={data.main_3d} inview={inview}/>
    }

    </InView>
</div>

);
}
};



function mapStateToProps(state) {
  return {
    ClickSection:state.ClickSection
  };
}
export default connect(mapStateToProps,{clicktoSection})(DepthImage);


// <div id="identify-intro" className="home-section__slice">
//     <div className="containerScroll container--full-width">
//         <div className="slice_scroll">
//
//             <div className="containerScroll container--ultra--wide">
//                 <div className="section-intro">
//                     <div className="section-title content-block">
//                         <div className="content-block__outer">
//                         <div ref={TextRef} className="content-block__inner">
//                             <h3 className="section-title__text intro_title_pre">
//                           {data.title === undefined ? data.Title : data.title }
//                             </h3>
//                         </div>
//                             <div ref={TitleRef} className="content-block__inner">
//                                 <h2 className="section-title__text intro_title">
//                                   Digital Infrastructure
//                                 </h2>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
