import React, { useEffect, useRef } from 'react'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
import ReactMarkdown from "react-markdown"
import { BgImage } from 'gbimage-bridge';
import { getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

gsap.registerPlugin(ScrollTrigger);

//
const NewBrand1col = ({ children, data, pinspace, headerRef, location, controller,scrollProgress,sectionnumber,section,scrubspeed,anticipate,setup,scroller, setScrollPos, scrollref, pagenav}) => {

    let revealRef = React.createRef();
    let TitleRef = React.createRef();
    let LinkRefs = useRef([]);
     LinkRefs.current = [];





  useEffect(() => {

    let tl = gsap.timeline({

        scrollTrigger: {
            trigger: revealRef.current,
            scroller: typeof window !== "undefined" && window.scroll.el,
            start: "-150px 60%",
            end: "80% bottom",
            scrub: 0.3,
            anticipatePin: anticipate,
            pinSpacing:false,
            pin: false,
            onEnter: () => controller(scrollref,sectionnumber),
            onEnterBack: () => controller(scrollref,sectionnumber),
            onUpdate: self =>  scrollProgress(self.progress)
        }


      });

      const mediaQuery = typeof window !== "undefined" && window.matchMedia("(prefers-reduced-motion: reduce)");
      if (!mediaQuery || mediaQuery.matches) {

        tl.from(TitleRef.current, {duration: 2, opacity: 1,  y: 0})
         .addLabel(scrollref);

        }else{

      tl.from(LinkRefs.current, {duration:2, opacity: 0, y:"100vh",stagger: 0.6 })
        .addLabel(scrollref);

      }
      const timer2 = setTimeout(() => {
                setScrollPos(scrollref , 0)
                 }, 300);


  }, []);

  function returnImage(image){
let pluginImage = getImage(image);

    return ([
      pluginImage,
      `linear-gradient(0deg, rgba(71,100,69,0.7025997899159664) 0%, rgba(71,100,69,0.8645833333333334) 100%)`
      ].reverse()
    )

  }

  const addToRefs = (el) => {
    if (el && !LinkRefs.current.includes(el)) {
      LinkRefs.current.push(el);
    }
  };

  return(

    <div ref={revealRef} >


    <section className="w-full antialiased text-base text-white md:pb-5 lg:py-10 py-5 bg-GoblinBlue-40">

    <div className="mx-auto xl:max-w-7xl max-w-6xl md:flex px-6 md:px-12 lg:px-6 md:my-10 my-14 z-20">
        <div className="mx-auto lg:w-5/6 w-full">

    <h2 className="mb-6 md:text-5xl text-black text-4xl text-center z-50 leading-vtight">{data.title}</h2>
         <div className="prose max-w-none w-full text-xl text-center leading-tight ">
          <ReactMarkdown source={data.text} escapeHtml={false} linkTarget='_blank'/>
        </div>
            </div>
          </div>

      <div className="w-full flex flex-row justify-center  flex-wrap ">
        {data.friends_object.map((document, index) => (
        <div key={index} ref={addToRefs} className="lg:flex-3 flex-0 w-full px-4 md:my-4 my-2" >
        <BgImage image={returnImage(document.image.localFile)} Tag="div" className="mx-auto h-full xl:max-w-7xl max-w-6xl w-full px-6 md:px-6 py-5 lg:px3 md:my-4 my-1 transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">
        {document.link_out !== null ?
          <a href={document.link_out.url} target="_blank" rel="noreferrer">
            <div className="mx-auto w-full h-full text-center my-5">

                <h2 className="mb-8 h-16 md:text-6xl lg:text-4xl text-4xl w-full ">{document.title}</h2>
                <div className="prose-white max-w-none md:px-5 px-2 pb-6 md:h-48 h-44 mb-5 text-base font-body">
                  <ReactMarkdown source={document.text} escapeHtml={false} linkTarget='_blank'/>
                </div>
                {document.link_out !== null &&
                <div className="mt-10 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body text-lg  cursor-pointer mx-auto mb-8">{document.link_out.subtitle}</div>
              }
            </div>
            </a>
            :<></>}
            {document.articles !== null ?
              <Link to={`${document.articles.type}/${document.articles.slug}`}>
                <div className="mx-auto w-full h-full text-center my-5">

                    <h4 className="mb-8 h-16 md:text-6xl lg:text-4xl text-5xl w-full ">{document.title}</h4>
                    <div className="prose-white max-w-none md:px-5 px-2 pb-6 md:h-48 h-44 mb-5 text-base font-body">
                      <ReactMarkdown source={document.text} escapeHtml={false} linkTarget='_blank'/>
                    </div>

                    <div className="mt-10  cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body text-lg  cursor-pointer mx-auto mb-8">Find out more</div>

                </div>
                </Link>
                :<></>}
        </BgImage>
        </div>
        ))}
      </div>
    </section>



    </div>

);
};


export default NewBrand1col;
