import React, { useEffect, useRef } from 'react'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
import ReactMarkdown from "react-markdown"
import { BgImage } from 'gbimage-bridge';
import { getImage } from "gatsby-plugin-image"
import StrapiImage from '../../components/StrapiImage_new.js'
import { Link } from 'gatsby'

gsap.registerPlugin(ScrollTrigger);

//
const NewBrand1col = ({side, children, data, pinspace, headerRef, location, controller,scrollProgress,sectionnumber,section,scrubspeed,anticipate,setup,scroller, setScrollPos, scrollref, pagenav}) => {

    let revealRef = React.createRef();
    let TitleRef = React.createRef();
    let ImageRef = React.createRef();



  useEffect(() => {

    let tl = gsap.timeline({

        scrollTrigger: {
            ease: "sine.inOut",
            trigger: revealRef.current,
            scroller: typeof window !== "undefined" && window.scroll.el,
            start: "top 95%",
            end: "50% 50%",
            scrub: 0.3
        }


      });

      const mediaQuery = typeof window !== "undefined" && window.matchMedia("(prefers-reduced-motion: reduce)");
      if (!mediaQuery || mediaQuery.matches) {
          tl.from(TitleRef.current, {duration: 2, opacity: 1,  y: 0})
           .addLabel(scrollref);
        }else{
          tl.from(TitleRef.current, {duration:2, x:side ? "-100vw":"100vw"})
          .from(ImageRef.current, {duration:2, xPercent:side ? "-100":"100"},"-=0.3")
            .addLabel(scrollref);
      }

  }, []);


  return(
    <div ref={revealRef} className="hello">

            {side ?
            <div ref={TitleRef} className="my-10 w-11/12 h-5/6  bg-DarkGreenery ">
              <div className="w-full lg:flex">
            <BgImage  image={getImage(data.image.localFile)} Tag="div" className="h-auto w-1/3 md:block hidden"/>
            <div ref={ImageRef} className="h-2/5 lg:w-2/3 w-full p-6 pb-0 bg-DarkGreenery">
            <h2 className="xl:text-10xl lg:text-9xl text-4xl hsm:text-5xl pt-5 text-white leading-vtight">{data.title}</h2>

            <div className="prose text-white max-w-none pt-5 mb-7 lg:text-2xl hsm:text-lg text-base font-body leading-snug">
            <ReactMarkdown source={data.subtitle} escapeHtml={false} linkTarget='_blank'/>
            </div>
            <div className="prose text-white max-w-none mb-2  lg:text-lg hsm:text-base  text-sm font-body leading-snug">
            <ReactMarkdown source={data.text} escapeHtml={false} linkTarget='_blank'/>
            </div>
              <div className="w-full flex flex-row flex-wrap">
            <div className="w-2/4 text-5xl pt-2 font-display leading-vtight pt-9">{data.cost}</div>
            {data.article !== null?
            <Link className="ml-auto w-2/4 " to={`${data.article.type}/${data.article.slug}`} state={{returnPage: location.pathname ,linkText:"Back",section:scrollref}} >
            <div className="mt-10 mb-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body md:text-2xl text-sm hsm:text-xl transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">Find out more</div>
        </Link>

        :          <Link className="ml-auto w-2/4" to="/contact">
                <div className="mt-10 mb-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body md:text-2xl text-sm hsm:text-xl transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">Contact for bookings</div>
            </Link>
      }
            </div>



            </div>
                </div>



            </div>

            :
<>

            <div ref={TitleRef} className="ml-auto my-10 w-11/12 h-5/6  bg-DarkGreenery ">
              <div className="w-full flex">

            <div ref={ImageRef} className="h-2/5 lg:w-2/3 w-full p-6 pb-0 bg-DarkGreenery z-50">
            <h2 className="xl:text-10xl lg:text-9xl text-4xl hsm:text-5xl pt-5 text-white leading-vtight">{data.title}</h2>

            <div className="prose text-white max-w-none pt-5 mb-7 lg:text-2xl hsm:text-lg text-base font-body leading-snug">
            <ReactMarkdown source={data.subtitle} escapeHtml={false} linkTarget='_blank'/>
            </div>
            <div className="prose text-white max-w-none mb-2  lg:text-lg hsm:text-base  text-sm font-body leading-snug">
            <ReactMarkdown source={data.text} escapeHtml={false} linkTarget='_blank'/>
            </div>
              <div className="w-full flex flex-row flex-wrap">
            <div className="w-2/4 text-5xl pt-2 font-display leading-vtight pt-9">{data.cost}</div>

            {data.article !== null?
            <Link className="ml-auto w-2/4 " to={`${data.article.type}/${data.article.slug}`} state={{returnPage: location.pathname ,linkText:"Back",section:scrollref}} >
            <div className="mt-10 mb-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body md:text-2xl text-sm hsm:text-xl transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">Find out more</div>
        </Link>

        :          <Link className="w-2/4" to="/contact">
                <div className="mt-10 mb-5 cursor-pointer bg-GoblinBlue bg-opacity-60 border-2 border-white w-full text-center text-white p-2 pt-2.5 font-body md:text-2xl text-sm hsm:text-xl transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">Contact for bookings</div>
            </Link>
      }


            </div>

            </div>
              <BgImage image={getImage(data.image.localFile)} Tag="div" className="h-auto w-1/3 md:block hidden"/>
                </div>



            </div>

</>}
</div>




);
};



// <BgImage image={returnImage(data.image.localFile)} Tag="div" className="mx-auto h-5/6 xl:max-w-7xl max-w-6xl px-6 md:px-6 py-5 lg:px3 md:my-4 my-1 transition ease-out duration-300 transform hover:scale-95 motion-reduce:transform-none cursor-pointer">
//
//     <div className="mx-auto md:w-5/6 w-full text-center">
//         <h4 className="mb-8 h-16 md:text-3xl lg:text-2xl xl:text-3xl text-xl">{data.title}</h4>
//         <div className="prose-white max-w-none md:px-5 px-2 pb-6 md:h-40 h-44 mb-5 text-base ">
//           <ReactMarkdown source={data.text} escapeHtml={false} linkTarget='_blank'/>
//         </div>
//         <div className="group transition ease-out duration-300 transform hover:scale-105 hover:font-display hover:font-bold hover:text-AECOM_Activation motion-reduce:transform-none font-display font-medium text-AECOM_Activation md:text-lg text-base"><span className="group-hover:text-AECOM_Activation-80 transform hover:scale-105 transition ease-out duration-300 transform" >{data.subtitle}</span> <svg className="group-hover:ml-5 group-hover:fill-AECOM_Activation-80 ml-3 w-5 h-5 -mt-0.5 fill-current inline ease-out duration-300 transform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M9 0C4 0 0 4 0 9s4 9 9 9v-1.7c-3.7 0-6.8-2.8-7.2-6.4h8.7l-2.7 2.6L9 13.7 13.8 9 9 4.3 7.8 5.5l2.6 2.6H1.8C2.2 4.5 5.3 1.7 9 1.7c4 0 7.3 3.3 7.3 7.3H18c0-5-4-9-9-9"/></svg></div>
//     </div>
// </BgImage>


export default NewBrand1col;
