import React, { Component } from 'react'
import {graphql} from "gatsby"
import SEO from "../components/seo"
import { InView } from 'react-intersection-observer'
import ScrollTrigger from 'gsap/ScrollTrigger';
import { connect } from 'react-redux';
import { setHorizontal, setScroll, setSectionNumber,setHorizontalSection, scrolltoSection  } from '../actions';
import DepthImage from "../scroll/DepthImage.js"
import TextLeft from "../scroll/wyeld/text-left.js"
import TextRight from "../scroll/wyeld/text-right.js"
import Staggered from "../scroll/wyeld/staggered.js"
import Friends from "../scroll/wyeld/friends.js"







class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Minimised:false,
      menuOpen: false,
      scrollpercent:0,
      scrollsection:"intro",
      selectedSection:"",
      scrollToSections:[],
      subSectionsShow:false,
      sectionnumber:0,
      mountscroll:false,
      sections:[],
      Horizontalsection:{number:0,active:false}
    }
    this.OG = ""
    if(this.props.data.strapiHomepage.social_image !== null){
    this.OG = this.props.data.strapiHomepage.social_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.setScrollPos = this.setScrollPos.bind(this)
    this.scrollProgress = this.scrollProgress.bind(this)
    this.ThreeController = this.ThreeController.bind(this)
    this.ScrolltoSection = this.ScrolltoSection.bind(this)

    this.ScrollRef = React.createRef();
    this.DontChangeScroll = true;

  }
  componentWillUnmount(){

    ScrollTrigger.getAll().map((scrolltrigger,index)=>{
        scrolltrigger.kill(true )
      })
  }

  componentDidMount(){

    ScrollTrigger.refresh(true)

this.setState({scrollToSections:[]})

if (typeof window !== 'undefined') {

this.DontChangeScroll = false


console.log("Mount", this.props.ClickSection)
if(this.props.ClickSection !== undefined){
          if(this.props.ClickSection.length !== 0){
            const timer3 = setTimeout(() => {
              this.ScrolltoSection(this.props.ClickSection)
               this.DontChangeScroll = false
             }, 400);
        }

}
}

}

ScrolltoSection(section_name){

  if (typeof window !== "undefined") {
    const yourElement = this.refs[section_name]
    const yCoordinate = yourElement.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;

    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: 'auto'
    });
  }

}


handleScroll(inView, entry ,subsection) {
    if(inView ){
        if(!this.DontChangeScroll){
          this.props.scrolltoSection(subsection)
        }
  }
}
setScrollPos(section, percent){
    let scroll = this.state.scrollToSections
    scroll.push({section:section,position:percent})
    this.setState({scrollToSections:scroll})

      if(this.props.data.strapiHomepage.content.length === scroll.length){
            ScrollTrigger.refresh(true)
      }

}
scrollProgress(progress){
    this.props.setScroll(progress)
}

ThreeController(section,number) {
    if(!this.DontChangeScroll){
      this.props.setSectionNumber(number)
    }
}
componentDidUpdate(prevProps){


  if(prevProps.ClickSection !== this.props.ClickSection){
    console.log("update", this.props.ClickSection)
if(!this.DontChangeScroll){
 if (typeof window !== "undefined") {
   const yourElement = this.refs[this.props.ClickSection]

   const yCoordinate = yourElement.getBoundingClientRect().top + window.pageYOffset;
   const yOffset = -80;

   window.scrollTo({
     top: yCoordinate + yOffset,
     behavior: 'auto'
   });
 }
 }


}

}




    render() {
      return (
    <div className="homeoverflow">
    <SEO title="Home" metaImage={this.OG} description={this.props.data.strapiHomepage.meta_description} title="Home"/>
<main role="main" className="hsm:mt-10 mt-20">
{this.props.data.strapiHomepage.content !== null &&
this.props.data.strapiHomepage.content.map((document, index) => (


        <InView as="div"  className="m-0 p-0" threshold={0.2} key={`section ${index}`} onChange={(inView, entry) => this.handleScroll(inView, entry, document.sectionid === null ? document.Sectionid : document.sectionid)}>
        <div className="m-0 p-0 inline" ref={document.sectionid === null ? document.Sectionid : document.sectionid} >
{document.type !== null && document.type.includes("ThreeD_depth") ? <DepthImage key={`homesection_${index}`}  scroll={ScrollTrigger} data={document} controller={this.ThreeController} sectionnumber={document.ID} location={this.props.location} scrollProgress={this.scrollProgress} scrubspeed={this.Scrubspeed} anticipate={false} pagenav={this.PageNav}  pagenav={this.PageNav} scrollref={ document.sectionid === null ? document.Sectionid : document.sectionid} scroller={this.state.ScrollREF} setScroll={this.setScroll} setScrollPos={this.setScrollPos}/>:<></>}
{document.type === "text_left"  ? <TextLeft key={`homesection_${index}`}  scroll={ScrollTrigger} data={document} controller={this.ThreeController} sectionnumber={document.ID} location={this.props.location} scrollProgress={this.scrollProgress} scrubspeed={this.Scrubspeed} anticipate={false} pagenav={this.PageNav}  pagenav={this.PageNav} scrollref={ document.sectionid === null ? document.Sectionid : document.sectionid} scroller={this.state.ScrollREF} setScroll={this.setScroll} setScrollPos={this.setScrollPos}/>:<></>}
{document.type === "text_right" ? <TextRight key={`homesection_${index}`}  scroll={ScrollTrigger} data={document} controller={this.ThreeController} sectionnumber={document.ID} location={this.props.location} scrollProgress={this.scrollProgress} scrubspeed={this.Scrubspeed} anticipate={false} pagenav={this.PageNav}  pagenav={this.PageNav} scrollref={ document.sectionid === null ? document.Sectionid : document.sectionid} scroller={this.state.ScrollREF} setScroll={this.setScroll} setScrollPos={this.setScrollPos}/>:<></>}
{document.strapi_component === "landing-page-content.homepagesection" ? <Staggered key={`homesection_${index}`}  scroll={ScrollTrigger} data={document} controller={this.ThreeController} sectionnumber={document.ID} location={this.props.location} scrollProgress={this.scrollProgress} scrubspeed={this.Scrubspeed} anticipate={false} pagenav={this.PageNav}  pagenav={this.PageNav} scrollref={ document.sectionid === null ? document.Sectionid : document.sectionid} scroller={this.state.ScrollREF} setScroll={this.setScroll} setScrollPos={this.setScrollPos}/>:<></>}
{document.strapi_component === "landing-page-content.friends" ? <Friends key={`homesection_${index}`}  scroll={ScrollTrigger} data={document} controller={this.ThreeController} sectionnumber={document.ID} location={this.props.location} scrollProgress={this.scrollProgress} scrubspeed={this.Scrubspeed} anticipate={false} pagenav={this.PageNav}  pagenav={this.PageNav} scrollref={ document.sectionid === null ? document.Sectionid : document.sectionid} scroller={this.state.ScrollREF} setScroll={this.setScroll} setScrollPos={this.setScrollPos}/>:<></>}
</div>
        </InView>
))}


</main>


    </div>

  )
}
}
function mapStateToProps(state) {
  return {
    articles: state.articles,
    pods:state.pods,
    WL:state.WL,
    micrositeID:state.micrositeID,
    ScrollSection:state.ScrollSection,
    ClickSection:state.ClickSection
  };
}
export default connect(mapStateToProps,{ setHorizontal, setScroll, setSectionNumber, setHorizontalSection ,scrolltoSection})(Index);
export const pageQuery = graphql`
  query ScrollIndexxQuery {
    strapiHomepage {
      content {
            Background
            DisplayType
            Sectionid
            Tile_text_pacement
            Title
            button_text
            hide_from_menu
            sectionid
            strapi_component
            subtitle
            text
            title
            type
            friends_object {
              text
              title
              image {
              localFile {
                 childImageSharp {
                   gatsbyImageData(quality: 80)
                 }
               }
             }
             articles {
               slug
               type
               title
             }
              link_out {
                title
                subtitle
                url
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 80)
                    }
                  }
                }
              }
            }
            depth_3d {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, width: 1920)
                }
              }
            }
            main_3d {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, width: 1920)
                }
              }
            }
            article {
              slug
              title
              type
            }
            Swims {
              article {
                slug
                title
                type
              }
              image {
             localFile {
               childImageSharp {
                 gatsbyImageData(quality: 80)
               }
             }
           }
              title
              text
              subtitle
              cost
            }
          }
          title
          meta_description
          social_image {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 2.1, quality: 80, width: 800)
              }
            }
          }

      }
    }


`
